/* .main-app {
    text-align: center;
    margin: 100px;
} */

.cta-button {
    padding: 15px;
    border: none;
    border-radius: 12px;
    min-width: 250px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.connect-wallet-button {
    background: rgb(32, 129, 226);
}

.mint-nft-button {
    background: orange;
}

.main-app {
    text-align: center;
    margin: 50px;
}

.logo-app {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
